






























































































import Vue from "vue";

export default Vue.extend({
  name: "EasyBottomSheet",
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    onHide: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    onClose() {
      this.$emit("update:isOpened", false);
      this.onHide();
    },
  },
});
