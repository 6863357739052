<template>
  <transition name="fast-fade">
    <div
      v-if="isSocialOpened"
      class="social-helpers"
      :class="{ isActive: isSocialOpened }"
    >
      <div @click="onClose" class="social-helpers__overlay"></div>
      <div class="social-helpers__item social-helpers__close" @click="onClose">
        <span><img src="@/assets/img/parcel/Close.svg"/></span>
      </div>
      <div class="social-helpers__item" @click="openViber">
        {{ $t("cardHelpersViberText") }}
        <img src="@/assets/img/parcel/Viber.svg" />
      </div>
      <div class="social-helpers__item" @click="openTelegram">
        {{ $t("cardHelpersTelegramText") }}
        <img src="@/assets/img/parcel/Telegram.svg" />
      </div>
      <div class="social-helpers__item" @click="openWhatsapp">
        {{ $t("cardHelpersWhatsappText") }}
        <img src="@/assets/img/parcel/Whatsapp.svg" />
      </div>
      <div
        v-if="userCountryPhone"
        class="social-helpers__item"
        @click="openCall"
      >
        {{ $t("cardHelpersPhoneText") }}
        <img src="@/assets/img/Call.svg" />
      </div>
      <!-- <div class="social-helpers__item" @click="openFaq">
        {{ $t("cardHelpersFaqText") }}
        <div class="social-helpers__question-icon"><span>?</span></div>
      </div> -->
    </div>
  </transition>
</template>

<script>
import eventsRegister from "@/services/events-register";
import { mapGetters } from "vuex";
import { logEventFA, openExternal } from "../utils/utils";

export default {
  name: "SocialHelp",
  props: {
    isSocialOpened: Boolean,
    parcelId: Number,
  },
  computed: {
    ...mapGetters("user", ["user", "userCountrySiteUrl", "userCountryPhone"]),
  },
  methods: {
    onClose() {
      this.$emit("update:isSocialOpened", false);
    },
    openCall() {
      openExternal(`tel:${this.userCountryPhone}`);
    },
    openFaq() {
      openExternal(this.userCountrySiteUrl + "/poriadok-povernennia-tovariv");
    },
    openViber() {
      const viberBot = process.env.VUE_APP_VIBERBOT;
      const viberURI =
        viberBot + "&context=" + this.user.pr236 + "&text=/start";
      if (!window["device"]) {
        openExternal(viberURI);
        this.onClose();
        return;
      }

      // subscribe viber
      //viber://pa?chatURI=easyget&context=2499342&text=/start
      window["plugins"].launcher.launch(
        {
          uri: viberURI,
        },
        (res) => console.log("success viber", res),
        (err) => {
          console.log("viber", err);
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("errorOpenViber"),
          });
        }
      );
      logEventFA("select_content", {
        content_type: "viber",
        customer_id: this.user.ID,
      });
      this.onClose();
    },
    openTelegram() {
      const telegramBot = process.env.VUE_APP_TELEGRAMBOT;
      openExternal(
        `${telegramBot}?start=${this.user.pr236}${
          this.parcelId ? "&parcelId=" + this.parcelId : ""
        }`
      );
      logEventFA("select_content", {
        content_type: "telegram",
        customer_id: this.user.ID,
      });
      this.onClose();
    },
    openWhatsapp() {
      const whatsAppNumber = 13055048599;
      openExternal(`https://wa.me/${whatsAppNumber}`);
    },
  },
};
</script>

<style lang="scss">
.social-helpers {
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 6;

  &__overlay {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #282828;
    opacity: 0.9;
    z-index: -1;
  }

  &__question-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-left: 6px;
    margin: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #91c258;

    span {
      color: #fff;
      padding-top: 4px;
      font-size: 24px;
      line-height: 1;
      font-family: "Helvetica Neue Medium";
    }
  }

  &__item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    color: #fff;
    transform: translateY(-100px);
    opacity: 0;
    transition: 0.3s;
    min-width: 280px;

    .isActive & {
      transform: translateX(0);
      opacity: 1;
    }

    img {
      display: block;
      margin-left: 6px;
      margin: 12px;
      max-width: 42px;
    }
  }

  &__close {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      width: 42px;
      height: 42px;
      margin: 12px;
    }
  }
}
</style>
